import React from "react";
import { Text, TextEnum } from "@trustana/pattern-library";

interface PageContainerProp {
  children: React.ReactNode;
}

const PageContainer: React.FC<PageContainerProp> = ({ children }) => (
  <div className="flex h-full min-h-screen flex-col bg-gray-50 min-w-[360px]">
    <div className="flex flex-col py-12 sm:py-24 sm:px-6 lg:px-8 text-center">
      <img
        className="mx-auto h-[50px] w-auto"
        src="/images/logo.png"
        alt="Trustana Logo"
      />
      <Text type={TextEnum.H4} className="mt-6 text-gray-900">
        Trustana Admin Portal
      </Text>
      {children}
    </div>
  </div>
);

export default PageContainer;
