import { useEffect, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import styles from "./input.module.scss";

interface InputFooterProps {
  error?: boolean;
  errorMessage?: string;
  helperText?: string;
  readOnly?: boolean;
}

export const InputFooter: React.FC<InputFooterProps> = ({
  errorMessage,
  helperText,
  error,
  readOnly,
}) => {
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    setShowErrorMessage(Boolean(error && !readOnly && errorMessage));
  }, [error, errorMessage, readOnly]);

  return (
    <>
      {showErrorMessage && (
        <div className={styles.errorContainer}>
          <ExclamationCircleIcon className={styles.errorIcon} />
          <span className={styles.message}>{errorMessage}</span>
        </div>
      )}
      {helperText && <div className={styles.helperText}>{helperText}</div>}
    </>
  );
};
