import { GetServerSideProps } from "next";
import { adminRoutes } from "@constants/routes";
import Login from "@pages/login/index/index";
import { getAuthInfo } from "@utils/auth";

export default Login;

export const getServerSideProps: GetServerSideProps<object> = async (ctx) => {
  const user = await getAuthInfo(ctx);

  if (user) {
    return {
      redirect: {
        destination: adminRoutes.home,
      },
      props: {},
    };
  }

  return {
    props: {},
  };
};
