import { gql, useMutation } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";
import { UserStatus } from "@constants/enum";

export interface LoginInput {
  input: {
    email: string;
    password: string;
  };
}

export interface LoginResponse {
  adminLogin: {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    user: {
      id: string;
      email: string;
      name: string;
      status: UserStatus;
    };
  };
}

const LOGIN: TypedDocumentNode<LoginResponse, LoginInput> = gql`
  mutation Login($input: AdminLoginInput!) {
    adminLogin(input: $input) {
      refreshToken
      accessToken
      expiresIn
      user {
        id
        email
        name
        status
      }
    }
  }
`;

export const useLogin = () =>
  useMutation(LOGIN, {
    errorPolicy: "all",
  });
