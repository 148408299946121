import React from "react";
import styles from "./tooltip.module.scss";

export interface TooltipProps {
  title?: string;
  children?: React.ReactNode;
  width?: string;
  open?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ title, children, open, width }) =>
  open ? (
    <div className={styles.default} style={width ? { width } : {}}>
      <p className={styles.title}>{title}</p>
      <p className={title ? styles.content : ""}>{children}</p>
    </div>
  ) : null;
export default Tooltip;
