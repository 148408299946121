import { GraphQLError } from "graphql/error/GraphQLError";

export function getGraphqlErrorMessage(
  error: Array<GraphQLError>,
  translate = true
) {
  if (error && error.length > 0) {
    const errCode = error[0]?.extensions?.code;

    if (errCode === "401000") return errCode;

    switch (Number(errCode)) {
      case 400000:
        return translate
          ? "global:error.400000"
          : "Unexpected exception from client.";
      case 401000:
        return translate
          ? "global:error.401000"
          : "Required authorization is not provided.";
      case 402000:
        return translate
          ? "global:error.402000"
          : "Required parameters has not been provided.";
      case 402001:
        return translate
          ? "global:error.402001"
          : "Name has not been provided.";
      case 402002:
        return translate
          ? "global:error.402002"
          : "File key has not been provided.";
      case 402003:
        return translate
          ? "global:error.402003"
          : "Required product fields are missing.";
      case 402004:
        return translate
          ? "global:error.402004"
          : "User ID has not been provided.";
      case 400006:
        return translate
          ? "global:error.400006"
          : "Too many login attempts. please try again later.";
      case 422000:
        return translate
          ? "global:error.422000"
          : "The username or password you entered is incorrect.";
      case 422001:
        return translate
          ? "global:error.422001"
          : "External ID provided is invalid.";
      case 422002:
        return translate
          ? "global:error.422002"
          : "Supplier ID provided is invalid.";
      case 422003:
        return translate
          ? "global:error.422003"
          : "Email address provided is invalid.";
      case 422004:
        return translate
          ? "global:error.422004"
          : "User ID provided is invalid.";
      case 422005:
        return translate
          ? "global:error.422005"
          : "Odoo verification code provided is invalid.";
      case 422006:
        return translate
          ? "global:error.422006"
          : "Verification code is invalid.";
      case 422007:
        return translate
          ? "global:error.422007"
          : "Current password is incorrect.";
      case 422008:
        return translate ? "global:error.422008" : "Name provided is invalid.";
      case 422009:
        return translate
          ? "global:error.422009"
          : "Product ID provided is invalid.";
      case 422011:
        return translate
          ? "global:error.422011"
          : "File key provided does not exist.";
      case 416000:
        return translate
          ? "global:error.416000"
          : "Parameter provided has exceeded the maximum length.";
      case 416001:
        return translate
          ? "global:error.416001"
          : "Name provided has exceeded the maximum length.";
      case 416002:
        return translate
          ? "global:error.416002"
          : "Phone number provided has exceeded the maximum length.";
      case 416003:
        return translate
          ? "global:error.416003"
          : "Email address provided has exceeded the maximum length.";
      case 416004:
        return translate
          ? "global:error.416004"
          : "File provided has exceeded the maximum file size.";
      case 403000:
        return translate
          ? "global:error.403000"
          : "You have no permission to access this page.";
      case 404000:
        return translate ? "global:error.404000" : "Not found.";
      case 404001:
        return translate
          ? "global:error.404001"
          : "The provided user is incorrect.";
      case 404002:
        return translate
          ? "global:error.404002"
          : "Please input your registered email address.";
      case 409001:
        return translate
          ? "global:error.409001"
          : "The email or phone number is already registered. Please login or register with a different email or phone number.";
      case 409002:
        return translate
          ? "global:error.409002"
          : "The email or phone number is already registered. Please login or register with a different email or phone number.";
      case 500000:
        return translate
          ? "global:error.500000"
          : "Internal server error has occurred.";
      case 601001:
        return translate
          ? "global:error.601001"
          : "Please click on the verification link in your email to activate your account.";
      case 601002:
        return translate
          ? "global:error.601002"
          : "Email address has been verified.";
      case 601003:
        return translate
          ? "global:error.601003"
          : "Mapping is still progress, please try again later.";
      case 601004:
        return translate
          ? "global:error.601004"
          : "File provided is still processing.";
      case 601005:
        return translate
          ? "global:error.601005"
          : "No file in queue. Please upload a file.";
      case 701001:
        return translate
          ? "global:error.701001"
          : "File format provided is not supported.";
      case 701002:
        return translate
          ? "global:error.701002"
          : "File parsing exception, please try again later.";
      case 701003:
        return translate
          ? "global:error.701003"
          : "File handling exception, please try again later.";
      case 701004:
        return translate ? "global:error.701004" : "File provided is empty.";
      case 701005:
        return translate
          ? "global:error.701005"
          : "Unexpected error, please try again later.";
      case 409003:
        return translate
          ? "global:error.409003"
          : "Sorry, the registration link has been used, please contact your sales manager for support.";
      default:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return error?.response?.errors.length > 0
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error.response.errors[0].message
          : error;
    }
  }
  return String(
    error ?? translate
      ? "global:error.unexpectedError"
      : "Unexpected error, please try again later."
  );
}
