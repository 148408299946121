export const adminRoutes = {
  home: `/home`,
  products: {
    index: "/product/management",
    management: "/product/management",
    upload: {
      index: "/product/upload",
      import: "/product/upload/import",
    },
    edit: {
      index: "/product/edit",
      id: (id: string) => `/product/edit/${id}`,
    },
    enrichment: {
      index: "/product/enrichment/create",
      detail: {
        index: "/product/enrichment",
        id: (taskId: string) => `/product/enrichment/${taskId}`,
      },
    },
  },
  accountManagement: {
    index: "/account-management",
    id: (id: string) => `/account-management/${id}`,
  },
  uploadTool: {
    index: "/upload-tool",
    upload: "/upload-tool/upload",
  },
  suppliers: {
    index: "/suppliers",
    id: (id: string) => `/suppliers/${id}`,
  },
  login: "/login",
  logout: "/logout",
  register: {
    index: "/register",
    success: {
      email: (email: string) => `/register/success/${email}`,
    },
    verify: {
      token: (token: string) => `/register/verify/${token}`,
    },
  },
  attribute: {
    index: "/attributes",
  },
  compliance: {
    index: "/compliance",
  },
  featureToggleDemo: {
    index: "/feature-toggle-demo",
  },
  // settings: "/dashboard/settings", have no this page for now
};

export const restApiRoutes = {
  next: {
    upload: (uploadTag: string) => `/api/upload/${uploadTag}`,
    templateImport: (route: string) => `/api/upload/templateImport/${route}`,
  },

  templateImport: {
    createFile: (baseUrl: string) => `${baseUrl}/product/import/api/file`,
    status: (baseUrl: string) => `${baseUrl}/product/import/api/status`,
  },
};
