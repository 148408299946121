import { Ref, forwardRef, useState } from "react";
import clsx from "clsx";
import { useForm } from "@utils/hooks";
import { InputFooter } from "../input/inputFooter";
import { InputHeader } from "../input/inputHeader";
import styles from "./input.module.scss";

export interface InputProps {
  id?: string;
  value: string;
  label?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  helperText?: string;
  required?: boolean;
  readOnly?: boolean;
  validate?: ReturnType<typeof useForm>["validate"];
  autoComplete?: string;
}
// controlled input component
export const FormInput = forwardRef(
  (
    {
      id,
      validate,
      label,
      placeholder,
      className,
      inputClassName,
      onChange,
      onBlur,
      disabled,
      helperText,
      required,
      readOnly,
      value,
      autoComplete,
    }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [error, setError] = useState("");

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e);
      if (id && error && validate) {
        const message = validate(id, value);
        setError(message);
      }
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(e);
      if (id && validate) {
        const message = validate(id, value);
        setError(message);
      }
    };

    return (
      <div className={clsx(className)}>
        {label && <InputHeader label={label} required={required} />}

        <div className={clsx(styles.inputContainer)}>
          <input
            id={id}
            value={value}
            disabled={disabled}
            type="text"
            className={clsx(
              styles.inputBaseStyle,
              error ? styles.inputError : styles.input,
              inputClassName
            )}
            placeholder={placeholder}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            readOnly={readOnly}
            ref={ref}
            autoComplete={autoComplete}
          />
        </div>

        <InputFooter
          errorMessage={error}
          error={Boolean(error)}
          helperText={helperText}
        />
      </div>
    );
  }
);

FormInput.displayName = "FormInput";
