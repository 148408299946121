import { useState } from "react";
import Tooltip from "@components/tooltip";
import { ExclamationCircleIcon as ExclamationCircleOutlineIcon } from "@heroicons/react/24/outline";
import styles from "./input.module.scss";

interface InputHeaderProps {
  required?: boolean;
  label: string;
  tipMessage?: string;
  tipTitle?: string;
  tipWidth?: string;
}

export const InputHeader: React.FC<InputHeaderProps> = ({
  required,
  label,
  tipTitle,
  tipMessage,
  tipWidth,
}) => {
  const [showTip, setShowTip] = useState<boolean>(false);

  const handleMouseOver = () => {
    setShowTip(true);
  };
  const handleMouseOut = () => {
    setShowTip(false);
  };

  return (
    <>
      <div className={styles.labelSection}>
        <span className={styles.label}>{label}</span>
        {required && <span className={styles.requiredIcon}>*</span>}
        <div className={styles.tipContainer}>
          {tipMessage && (
            <ExclamationCircleOutlineIcon
              className={styles.tipIcon}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            />
          )}
          {tipMessage && (
            <div className={styles.tooltipParent}>
              <Tooltip title={tipTitle} open={showTip} width={tipWidth}>
                {tipMessage}
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
