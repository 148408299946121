import React from "react";

interface LoginCardProp {
  children: React.ReactNode;
}

const LoginCard: React.FC<LoginCardProp> = ({ children }) => (
  <>
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg text-left">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:p-8">
        {children}
      </div>
    </div>
  </>
);

export default LoginCard;
