import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Namespace } from "@i18n/index";
import { Alert, AlertProps } from "@trustana/pattern-library";

interface MessageBoxProp {
  query: Record<string, unknown>;
}

interface AlertMessageType {
  title: string;
  type?: AlertProps["type"];
}

export const MessageBox: React.FC<MessageBoxProp> = ({ query }) => {
  const { t, i18n } = useTranslation([Namespace.AUTH, Namespace.GLOBAL]);
  const [message, setMessage] = useState<AlertMessageType>();

  const keys = Object.keys(query);

  useEffect(() => {
    if (keys.includes("loggedout")) {
      setMessage({
        title: t("notification.successfulLogout") ?? "",
        type: "success",
      });
    } else if (keys.includes("expired")) {
      setMessage({
        title: t("notification.sessionExpired") ?? "",
        type: "warning",
      });
    } else if (keys.includes("login")) {
      setMessage({
        title: t("notification.updateEmailLogin") ?? "",
        type: "success",
      });
    } else {
      setMessage({
        title: "",
        type: "success",
      });
    }
  }, [i18n.resolvedLanguage]);

  return message?.title ? (
    <div className="mt-8 sm:mx-auto sm:max-w-lg">
      <Alert show title={message?.title} type={message?.type ?? "info"} />
    </div>
  ) : null;
};
