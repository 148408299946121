import { TFunction } from "i18next";
import { z } from "zod";

export const loginSchema = (t: TFunction) =>
  z.object({
    email: z
      .string()
      .nonempty(t("validation.emailRequired") || "Email address is required")
      .refine(
        (val) => z.string().email().safeParse(val.replace(/ /g, "")).success,
        { message: t("validation.emailInvalid") || "Email address is invalid" }
      ),
    password: z
      .string()
      .nonempty(t("validation.passwordRequired") || "Password is required"),
  });
