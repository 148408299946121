import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { GraphQLError } from "graphql/error/GraphQLError";
import { nanoid } from "nanoid";
import { useRouter } from "next/router";
import { FormInput, PasswordInput } from "@components/input-v2/index";
import { localStorageKey } from "@constants/localStorageKey";
import { adminRoutes } from "@constants/routes";
import { Namespace } from "@i18n/index";
import {
  Button,
  Checkbox,
  Text,
  TextEnum,
  Toast,
  ToastProps,
  useKeypress,
} from "@trustana/pattern-library";
import { setAuthCookies } from "@utils/auth";
import { getGraphqlErrorMessage } from "@utils/getErrorMessage";
import { useForm } from "@utils/hooks/useForm";
import { setLocalStorageValue } from "@utils/localStorage";
import LoginCard from "./components/LoginCard";
import { MessageBox } from "./components/MessageBox";
import PageContainer from "./components/PageContainer";
import { useLogin } from "./loader/auth";
import { loginSchema } from "./validate";

const Login = () => {
  const router = useRouter();
  const { t } = useTranslation([Namespace.AUTH, Namespace.GLOBAL]);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [remember, setRemember] = useState<boolean>(false);
  const [toastProps, setToastProps] = useState<
    ToastProps & { key: string | number }
  >({
    show: false,
    title: "",
    type: "success",
    key: "",
  });
  const { validate, onSubmitValidate, ref } = useForm({
    zod: loginSchema(t),
    data: {
      email,
      password,
    },
  });
  const [loginMutation, { loading }] = useLogin();
  const { query } = router;

  const login = async () => {
    const validated = onSubmitValidate();
    if (validated) {
      const loginResult = await loginMutation({
        variables: {
          input: {
            email,
            password,
          },
        },
      });
      if (loginResult.errors) {
        setToastProps({
          show: true,
          title: t(
            getGraphqlErrorMessage(loginResult.errors as GraphQLError[])
          ),
          type: "error",
          key: nanoid(),
        });
      }
      if (loginResult.data?.adminLogin) {
        // set it to localStorage
        setLocalStorageValue(localStorageKey.remember, remember.toString());
        setAuthCookies(
          {
            expiresIn: Number(loginResult.data.adminLogin.expiresIn),
            accessToken: loginResult.data.adminLogin.accessToken,
            refreshToken: loginResult.data.adminLogin.refreshToken,
          },
          remember
        );
        router.push(adminRoutes.home);
      }
    }
  };

  useKeypress("Enter", login);

  // const forgotPassword = () => {
  // router.push(adminRoutes.login.forgotPassword);
  // };

  const goRegister = () => {
    router.push(adminRoutes.register.index);
  };

  return (
    <>
      <PageContainer>
        <MessageBox query={query} />
        <LoginCard>
          <Text type={TextEnum.H3} className="mb-7 text-secondary-6">
            {t("login.welcomeBack")}
          </Text>
          <FormInput
            id="email"
            label={t("login.email") ?? ""}
            autoComplete="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
            ref={ref}
            value={email}
            validate={validate}
          />
          <PasswordInput
            id="password"
            className="my-6"
            ref={ref}
            label={t("login.password") ?? ""}
            autoComplete="password"
            required
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            validate={validate}
          />
          <div className="flex items-center justify-between my-6">
            <Checkbox
              label={t("login.rememberMe")}
              onClick={(value) => {
                setRemember(value);
              }}
              checked={remember}
            />
            {/* <Button type="textLink" onClick={forgotPassword}>
              {t("login.forgotPassword")}
            </Button> */}
          </div>
          <Button
            className="w-full justify-center"
            onClick={login}
            disabled={loading}
          >
            {t("login.signIn")}
          </Button>
          <div className="mt-8 text-center">
            <Trans
              t={t}
              i18nKey="login.registerNewAccount"
              components={[
                <Text
                  type={TextEnum.Body_medium}
                  key="signUp"
                  className="text-secondary-6"
                >
                  <></>
                </Text>,
                <Button
                  key="signUpLink"
                  className="mx-auto"
                  onClick={goRegister}
                  type="textLink"
                >
                  <></>
                </Button>,
              ]}
            />
          </div>
        </LoginCard>
      </PageContainer>
      <Toast {...toastProps} position="center" />
    </>
  );
};

export default Login;
