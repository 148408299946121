import { Ref, forwardRef, useState } from "react";
import clsx from "clsx";
import { InputFooter } from "@components/input/inputFooter";
import { InputHeader } from "@components/input/inputHeader";
import {
  CheckCircleSolidIcon,
  EyeOffSolidIcon,
  EyeSolidIcon,
} from "@trustana/icons/react/solid";
import { Text, TextEnum } from "@trustana/pattern-library";
import { InputProps } from "../FormInput";
import styles from "./passwordInput.module.scss";

export type RuleOptionsType = {
  rules: RegExp;
  message: string;
}[];
interface PasswordInputProps extends InputProps {
  ruleOptions?: RuleOptionsType;
}

export const PasswordInput = forwardRef(
  (
    {
      label,
      required,
      id,
      value,
      disabled,
      className,
      inputClassName,
      placeholder,
      helperText,
      readOnly,
      onChange,
      onBlur,
      validate,
      ruleOptions,
    }: PasswordInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const checkValidity = (value: string) =>
      ruleOptions?.map((item) => ({
        key: item.message,
        isValid: item.rules.test(value),
      })) ?? [];
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState<
      { isValid: boolean; key: string }[]
    >(checkValidity(value));

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e);
      if (id && error && validate) {
        const message = validate(id, value);
        setError(message);
      }
      // use target value because state updates after on change, avoid chaining states.
      if (ruleOptions) setIsPasswordValid(checkValidity(e.target.value));
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(e);
      if (id && validate) {
        const message = validate(id, value);
        setError(message);
      }
    };
    const handleShowPassword = () => setShowPassword((prev) => !prev);

    return (
      <div className={clsx(className)}>
        {label && <InputHeader label={label} required={required} />}

        <div className={clsx(styles.inputContainer)}>
          <input
            id={id}
            value={value}
            disabled={disabled}
            type={showPassword ? "text" : "password"}
            className={clsx(
              styles.inputBaseStyle,
              error ? styles.inputError : styles.input,
              inputClassName
            )}
            placeholder={placeholder}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            readOnly={readOnly}
            ref={ref}
          />
          <div className={styles.eyeIconContainer}>
            {showPassword ? (
              <EyeSolidIcon
                role="button"
                onClick={handleShowPassword}
                width={20}
                height={20}
              />
            ) : (
              <EyeOffSolidIcon
                role="button"
                onClick={handleShowPassword}
                width={20}
                height={20}
              />
            )}
          </div>
        </div>
        <InputFooter
          errorMessage={error}
          error={Boolean(error)}
          helperText={helperText}
        />
        {ruleOptions && (
          <div className={clsx(styles.validationContainer)}>
            {ruleOptions.map((item, index) => {
              const { message } = item;

              return (
                <div
                  key={message}
                  className={clsx(
                    styles.base,
                    isPasswordValid[index].key === message &&
                      isPasswordValid[index].isValid
                      ? styles.successIconColor
                      : styles.defaultIconColor
                  )}
                >
                  <CheckCircleSolidIcon width={12} height={12} />
                  <Text type={TextEnum.Caption}>{message}</Text>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

PasswordInput.displayName = "PasswordInput";
